import {
  where,
  orderBy,
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  limit,
  addDoc,
  serverTimestamp,
  getCountFromServer,
  and,
} from "firebase/firestore";
import { firestore } from "../../firesbase.js";

/**
 * Firestore helper to get docs
 * @param {string} collectionPath - Path to collection (can be nested)
 * @param {QueryNonFilterConstraint[]} queryConstraints - .
 * @param {function(QueryDocumentSnapshot): T} collectFunc - function that takes the doc reference, can be async
 * @returns {Promise<T[]>} - array of results from calling collectFunc
 */
export const getFirestoreDocs = async (
  collectionPath,
  queryConstraints,
  collectFunc,
) => {
  const snap = await getDocs(
    query(collection(firestore, collectionPath), ...queryConstraints),
  );
  const data = [];
  for (const doc of snap.docs) {
    let value = collectFunc(doc);
    if (value instanceof Promise) {
      value = await value;
    }
    data.push(value);
  }

  return data;
};

/**
 * Firestore helper to get one doc from a collection (using limit(1))
 * @param {string} collectionPath - Path to collection (can be nested)
 * @param {QueryNonFilterConstraint[]} queryConstraints - .
 * @param {function(QueryDocumentSnapshot): T} collectFunc - function that takes the doc reference, can be async
 * @returns {Promise<T>} - result from calling collectFunc
 */
export const getFirestoreDoc = async (
  collectionPath,
  queryConstraints,
  collectFunc,
) => {
  const data = await getFirestoreDocs(
    collectionPath,
    [...queryConstraints, limit(1)],
    collectFunc,
  );
  return data[0];
};

/**
 * Firestore helper to get get count of a query
 * @param {string} collectionPath - Path to collection (can be nested)
 * @param {QueryNonFilterConstraint[]} queryConstraints - .
 * @returns {Promise<number>} - count from the query
 */
export const getFirestoreCount = async (collectionPath, queryConstraints) => {
  const q = query(collection(firestore, collectionPath), ...queryConstraints);
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

// Gets -----------------------------------------------------------------------

export const dbGetCurrentScenario = async () =>
  await getFirestoreDoc(
    "scenarios",
    [where("status", "==", "active")],
    (doc) => ({
      id: doc.id,
      data: doc.data(),
    }),
  );

export const dbGetMostRecentLocations = async () =>
  (
    await getFirestoreDocs(
      "users",
      [],
      async (userDoc) =>
        await getFirestoreDoc(
          "userLocations",
          [where("userId", "==", userDoc.id), orderBy("timestamp", "desc")],
          (doc) => ({
            id: doc.id,
            data: doc.data(),
            name: userDoc.data().name,
          }),
        ),
    )
  ).filter((x) => x);

export const dbGetAllScenarioMarkers = async (scenarioId) =>
  await getFirestoreDocs(
    "geopoints",
    [
      where(
        "scenarios",
        "array-contains",
        doc(firestore, `scenarios/${scenarioId}`),
      ),
    ],
    (doc) => doc.data(),
  );

export const dbGetAllMessages = async () =>
  await getFirestoreDocs("messages", [], (doc) => doc.data());

export const dbGetAllMessagesSeenByUser = async (userId) =>
  await getFirestoreDocs(
    "messages",
    [where("seenBy", "array-contains", userId)],
    (doc) => doc.data(),
  );

export const dbGetAllMessagesByType = async (type) =>
  await getFirestoreDocs("messages", [where("type", "==", type)], (doc) => ({
    [doc.id]: doc.data(),
  }));

export const dbGetAllAudiences = async () =>
  await getFirestoreDocs("audiences", [], (doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

export const dbGetAudienceCountByMessageType = async (audienceId, type) =>
  await getFirestoreCount("messages", [
    and(
      where("includeAudienceIds", "array-contains", audienceId),
      where("type", "==", type),
    ),
  ]);

// Creates --------------------------------------------------------------------

const _dbCreateNewGenericMessage = async (type, data) => {
  await addDoc(collection(firestore, "messages"), {
    type,
    timestamp: serverTimestamp(),
    ...data,
  });
};

export const dbCreateNewInAppMessage = async (data) => {
  await _dbCreateNewGenericMessage("inapp", data);
};

export const dbCreateNewPushMessage = async (data) => {
  await _dbCreateNewGenericMessage("push", data);
};

export const dbCreateNewAudience = async (data) => {
  await addDoc(collection(firestore, "audiences"), data);
};

// Updates --------------------------------------------------------------------

export const dbSetActiveScenario = async (newScenarioId) => {
  const oldScenario = await dbGetCurrentScenario();
  await updateDoc(doc(firestore, `scenarios/${oldScenario.id}`), {
    status: "inactive",
  });
  await updateDoc(doc(firestore, `scenarios/${newScenarioId}`), {
    status: "active",
  });
};

// New function to update an existing in-app message
export const dbUpdateInAppMessage = async (messageId, data) => {
  await updateDoc(doc(firestore, `messages/${messageId}`), {
    ...data,
    lastUpdated: serverTimestamp(),
  });
};

export const dbUpdateAudience = async (audienceId, data) => {
  await updateDoc(doc(firestore, `audiences/${audienceId}`), data);
};

